<script lang="ts">
  import { onMount } from "svelte";

  let bgColor = "";
  onMount(() => {
    const scrollHandler = () => {
      bgColor = window.scrollY > 50 ? "before:bg-black/20" : "";
    };
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });
</script>

<header
  class={`sticky top-0 w-full z-10 before:absolute before:w-full before:h-full before:backdrop-blur before:transition-colors before:duration-500 ${bgColor} before:-z-[1]`}
>
  <slot />
</header>
