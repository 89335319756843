<script lang="ts">
  import { page } from "$app/stores";

  export let href: string;
  export let name: string;
  $: active = $page.url.pathname.startsWith(href);
</script>

<a
  class="nav-link after:absolute after:h-px after:bg-white after:-bottom-[13px]"
  {href}
  class:active
  class:font-semibold={active}>{name}</a
>

<style lang="postcss">
  .active::after {
    content: "";
    width: calc(100% - 1.5rem);
  }
</style>
